import React, { FC, ReactNode } from 'react'

interface Buttonable {
    children?: ReactNode;
    className?: string;
    onClick?: () => any;
    id?: string;
    size?: "lg" | "sm" | "md";
    color?: "primary" | "secondary" | "winter";
    rounded?: "lg" | "sm" | "md";
}

const Button: FC<Buttonable> = ({ children, className, id, onClick, size, color, rounded }) => {
    className = className ? className : "";

    const paddingClassName = size === "lg"
        ? "py-[15px] px-10"
        : size === "md"
            ? "py-[10px] px-5"
            : size === "sm"
                ? "py-[5px] px-2"
                : "py-[10px] px-5";

    const textSizeClass = size === "lg"
        ? "text-lg"
        : size === "md"
            ? "text-[17px]"
            : size === "sm"
                ? "text-sm"
                : "text-[17px]";

    const colorClass = color === "primary"
        ? "from-cyan-500 to-blue-500 text-white"
        : color === "secondary"
            ? "bg-black text-white"
            : color === "winter"
                ? "text-black bg-white"
                : "text-black bg-white";

    const roundedClass = rounded === "lg"
        ? "rounded-full"
        : rounded === "md"
            ? "rounded-3xl"
            : rounded === "sm"
                ? "rounded-lg"
                : "rounded-2xl";


    return (
        <button className={`bg-gradient-to-r overflow-hidden ${colorClass} transition ${paddingClassName} duration-500 ${roundedClass} font-bold ${textSizeClass} ${className}`} id={id} onClick={onClick}>
            {children}
        </button>
    )
}

export default Button