import React, { FC, ReactNode } from 'react'

interface Containerable {
    children?: ReactNode;
    className?: string;
    id?: string;
    ref?: any;
}

const Container: FC<Containerable> = ({ children, className, id, ref }) => {
    return (
        <div
            className={`max-w-[1269px] mx-auto px-5 ${className}`}
            ref={ref}
            id={id}
        >
            {children}
        </div>
    )
}

export default Container