import React, { useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
import { motion } from 'framer-motion';

const CookieBanner = () => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleAllow = () => {
        localStorage.setItem('acceptedCookies', 'true');
        setIsOpen(false);
    }

    const rejectCookies = () => {
        localStorage.setItem('acceptedCookies', 'false');
        setIsOpen(false);
    }

    if (localStorage.getItem('acceptedCookies')) {
        return (
            <div>

            </div>
        )
    }

    return (
        <motion.div initial={{ y: 70, opacity: 0 }} animate={isOpen ? { y: 0, opacity: 1 } : { y: 70, opacity: 0, pointerEvents: "none" }} className='max-w-[448px] p-6 fixed bottom-5'>
            <div className='bg-white rounded-3xl p-6 shadow-xl'>
                <div className='flex items-start gap-2'>
                    <p className='text-[14px]'>
                        By clicking “Accept Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. View our Cookie Policy for more information.
                    </p>
                    <button onClick={handleClose} className='bg-gray-200 p-2 rounded-full'>
                        <FaXmark />
                    </button>

                </div>
                <div className='flex gap-5 mt-5 sm:flex-col'>
                    <button onClick={rejectCookies} className='border-1 border border-gray-300 p-[10px] px-[20px] rounded-lg'>Reject Cookies</button>
                    <button onClick={handleAllow} className='bg-black text-white p-[10px] px-[20px] rounded-lg'>Accept Cookies</button>
                </div>
            </div>
        </motion.div>
    );
};

export default CookieBanner;
