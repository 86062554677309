import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from './Login';
import { FaChevronRight, FaDoorOpen, FaMinus, FaPlus } from 'react-icons/fa6';
import { Accordion, AccordionItem, Alert, Button, Input, Textarea, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure, Select, SelectItem, TableBody, TableRow, image } from '@nextui-org/react';
import Cookies from 'cookies-js';
import axios from 'axios';
import * as api from "../services/api";
import { AlertDialog, Table } from '@radix-ui/themes';
import Departments from '../components/Departments';
import Services from '../components/Services';

export interface Department {
  id: number;
  title: string;
  description: string;
  doctor_id: number;
  price: number;
}

export interface DepartmentDTO {
  doctor_id: number;
  price: number;
  title: string;
  description: string;
}

const AdminPanel = () => {
  const auth = Boolean(Cookies.get('hasPermit')) || false;
  const location = useLocation();
  const navigate = useNavigate();
  const [images, setImages] = useState<string[]>(['']);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [websiteContent, setWebsiteContent] = useState({
    banner_title: '',
    banner_descr: '',
    about_us_descr: '',
    phone_number: '',
    email: '',
  });
  const { isOpen, onClose, onOpenChange } = useDisclosure();
  const [departmentFormData, setDepartmentFormData] = useState<DepartmentDTO>({
    title: '',
    description: '',
    doctor_id: 0,
    price: 0,
  });
  const [updatedDoctorFormData, setUpatedDoctorFormData] = useState<any>({
    first_name: '',
    last_name: '',
    father_name: '',
    phone_number: '',
    email: '',
    profile_picture: "",
    specialization: ""
  });
  const [doctors, setDoctors] = useState<any>([]);
  

  const [doctorForm, setDoctorForm] = useState({
    first_name: "",
    last_name: "",
    father_name: "",
    email: "",
    phone_number: 0,
    profile_picture: "",
    specialization: ""
  })

  const createDepartment = async () => {
    try {
      const response = await axios.post(`https://api.stepanavanmc.am/departments/create`, departmentFormData);
      console.log('Department created:', response.status);
      fetchDepartments()
      return response.data;
    } catch (error) {
      console.error('Error creating department:', error);
      throw error;
    }
  };

  const deleteDoctor = async (id: number) => {
    const response = await axios.delete("https://api.stepanavanmc.am/doctors/delete/" + id);
    fetchDoctors()
  }

  const editDoctor = async (doctor: any) => {
    const response = await axios.put("https://api.stepanavanmc.am/doctors/delete/" + doctor.id);
    fetchDoctors()
  }

  const handleEditDoctorData = (doctor: any) => {
    console.log(doctor);
    
    setUpatedDoctorFormData({
      id: doctor.id,
      first_name: doctor.first_name,
      last_name: doctor.last_name,
      father_name: doctor.father_name,
      phone_number: Number(doctor.phone_number),
      email: doctor.email,
      profile_picture: doctor.profile_picture,
      specialization: doctor.specialization
    })
  }

  useEffect(() => {
    console.log(updatedDoctorFormData);
    
  }, [updatedDoctorFormData])

  const fetchDoctors = async () => {
    const response = await axios.get("https://api.stepanavanmc.am/doctors/findAll");
    setDoctors(response.data)
  }

  const fetchDepartments = async () => {
    const response = await axios.get("https://api.stepanavanmc.am/departments/findAll");
    setDepartments(response.data);
  }

  const handleAddDoctor = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await axios.post("https://api.stepanavanmc.am/doctors/create", doctorForm);
    setDoctorForm({
      first_name: "",
      last_name: "",
      father_name: "",
      phone_number: 0,
      email: "",
      profile_picture: "",
      specialization: ""
    })
    await fetchDoctors();
  }

  useEffect(() => {
    if (auth) {
      fetchWebsiteContent();
      fetchDoctors();
      fetchDepartments();
    }
  }, [auth]);

  const fetchWebsiteContent = async () => {
    try {
      const data = await api.fetchWebsiteContent();
      setWebsiteContent(data[0]);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
    }
  };

  const handleUpdateDoctor = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(updatedDoctorFormData);
    
    const response = await axios.put("https://api.stepanavanmc.am/doctors/update/" + updatedDoctorFormData.id, updatedDoctorFormData);
    await fetchDoctors()
  }

  const handleWebsiteContentUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.put('https://api.stepanavanmc.am/website-content/update/1', websiteContent);
      
      alert('Website content updated successfully!');
    } catch (error) {
      console.error('Failed to update website content:', error);
    }
  };

  const handleChangeDoctor = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;

    setDoctorForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeUpdateDoctor = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;

    setUpatedDoctorFormData((prev: any) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleAddImage = () => {
    setImages((prev) => [...prev, '']);
  };

  const handleRemoveImage = (index: number) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleLogout = () => {
    Cookies.set('hasPermit', '');
    navigate('/adm.pnl');
  };

  if (!auth) {
    return <Login />;
  }

  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('route');

  if (page !== '/home/site-managemant' && auth) {
    return <Navigate to="/adm.pnl?route=/home/site-managemant" />;
  }

  return (
    <div className="max-w-[1200px] w-full mx-auto">
      <header className="bg-cyan-600 p-5 flex justify-between items-center">
        <div className="flex gap-1 text-white">
          {page
            ?.split('/')
            .map((route, index) => (
              <span key={index} className="flex gap-1 items-center">
                {route.charAt(0).toUpperCase() + route.slice(1)}
                {index < page.split('/').length - 1 && <FaChevronRight />}
              </span>
            ))}
        </div>
        <Button onClick={handleLogout} size={window.innerWidth > 700 ? 'lg' : 'md'} color="danger">
          <FaDoorOpen /> Logout
        </Button>
      </header>

      <div className="p-5 flex gap-5 sm:gap-3 items-center justify-center">
        <Link className="flex-1 h-[40px]" to="/adm.pnl?route=/home/site-managemant">
          <Button className="w-full" size={window.innerWidth > 700 ? 'lg' : 'md'} color={page === '/home/site-managemant' ? 'success' : 'warning'} variant="flat">
            Site Management
          </Button>
        </Link>
      </div>

      <div className="p-5">
        {page === '/home/site-managemant' && (
          <div className="mt-6 flex flex-col gap-10">
            {/* Website Content Form */}
            <form onSubmit={handleWebsiteContentUpdate} className="flex flex-col items-end gap-3">
              <Textarea
                name="banner_title"
                value={websiteContent ? websiteContent.banner_title : "Fetching..."}
                defaultValue={websiteContent ? websiteContent.banner_title : "Fetching..."}
                onChange={(e) => setWebsiteContent({ ...websiteContent, banner_title: e.target.value })}
                variant="underlined"
                label="Banner Title"
              />
              <Button type="submit" color="success" variant="flat">
                Save
              </Button>
            </form>

            <form onSubmit={handleWebsiteContentUpdate} className="flex flex-col items-end gap-3">
              <Textarea
                name="banner_descr"
                value={websiteContent ? websiteContent.banner_descr : "Fetching..."}
                defaultValue={websiteContent ? websiteContent.banner_descr : "Fetching..."}
                onChange={(e) => setWebsiteContent({ ...websiteContent, banner_descr: e.target.value })}
                variant="underlined"
                label="Banner Description"
              />
              <Button type="submit" color="success" variant="flat">
                Save
              </Button>
            </form>

            {/* About Us Section */}
            <form onSubmit={handleWebsiteContentUpdate} className="flex flex-col items-end gap-3">
              <Textarea
                value={websiteContent ? websiteContent.about_us_descr : "Fetching..."}
                onChange={(e) => setWebsiteContent({ ...websiteContent, about_us_descr: e.target.value })}
                variant="flat"
                label="About Us"
              />
              <Button type="submit" color="success" variant="flat">
                Save
              </Button>
            </form>
            <Departments doctors={doctors} />
            <Services />

            {/* Services Section */}
            <div>
              <h2 className="text-2xl mt-6">Our Docotrs</h2>
                <form action="" className='flex w-full flex-col gap-2 items-end'>
                  <Table.Root className='w-full'>
                    <Table.Header>
                      <Table.Row>
                        <Table.RowHeaderCell>#</Table.RowHeaderCell>
                        <Table.RowHeaderCell>First Name</Table.RowHeaderCell>
                        <Table.RowHeaderCell>Last Name</Table.RowHeaderCell>
                        <Table.RowHeaderCell>Father Name</Table.RowHeaderCell>
                        <Table.RowHeaderCell>Phone number</Table.RowHeaderCell>
                        <Table.RowHeaderCell>Email</Table.RowHeaderCell>
                        <Table.RowHeaderCell>Profile Picture</Table.RowHeaderCell>
                        <Table.RowHeaderCell>Specialization</Table.RowHeaderCell>
                        <Table.RowHeaderCell justify={"end"}>Actions</Table.RowHeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        doctors && doctors.map((doctor: any) => (
                          <Table.Row>
                            <Table.Cell>{doctor.id}</Table.Cell>
                            <Table.Cell>{doctor.first_name}</Table.Cell>
                            <Table.Cell>{doctor.last_name}</Table.Cell>
                            <Table.Cell>{doctor.father_name}</Table.Cell>
                            <Table.Cell>{doctor.phone_number}</Table.Cell>
                            <Table.Cell>{doctor.email}</Table.Cell>
                            <Table.Cell>{doctor.profile_picture}</Table.Cell>
                            <Table.Cell>{doctor.specialization}</Table.Cell>
                            <Table.Cell justify={"end"}>
                              <div className='w-full justify-end flex gap-4'>
                                <Button onClick={() => deleteDoctor(doctor.id)} color='danger'>Delete</Button>
                                <AlertDialog.Root>
                                  <AlertDialog.Trigger>
                                    <Button color='success' onClick={() => handleEditDoctorData(doctor)}>Edit</Button>
                                  </AlertDialog.Trigger>
                                  <AlertDialog.Content size={"3"}>
                                    <AlertDialog.Title>Update ({updatedDoctorFormData.first_name})</AlertDialog.Title>
                                    <form action="" onSubmit={handleUpdateDoctor} className='flex flex-col gap-3'>
                                      <Input value={updatedDoctorFormData.first_name} onChange={handleChangeUpdateDoctor} name='first_name' variant='underlined' label='First Name' />
                                      <Input value={updatedDoctorFormData.last_name} onChange={handleChangeUpdateDoctor} name='last_name' variant='underlined' label='Last Name' />
                                      <Input value={updatedDoctorFormData.father_name} onChange={handleChangeUpdateDoctor} name='father_name' variant='underlined' label='Father Name' />
                                      <Input value={updatedDoctorFormData.phone_number} onChange={handleChangeUpdateDoctor} name='phone_number' variant='underlined' label='Phone Number' />
                                      <Input value={updatedDoctorFormData.email} onChange={handleChangeUpdateDoctor} name='email' variant='underlined' label='Email' />
                                      <Input value={updatedDoctorFormData.profile_picture} onChange={handleChangeUpdateDoctor} name='profile_picture' variant='underlined' label='Profile picture' />
                                      <Input value={updatedDoctorFormData.specialization} onChange={handleChangeUpdateDoctor} name='specialization' variant='underlined' label='Specialization' />
                                      <div className='flex gap-3 justify-end'>
                                        <Button size='lg' color='success' type='submit'>Save changes</Button>
                                        <AlertDialog.Cancel>
                                          <Button size='lg' color='danger' type='button'>Close</Button>
                                        </AlertDialog.Cancel>
                                      </div>
                                    </form>
                                  </AlertDialog.Content>
                                </AlertDialog.Root>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table.Root>
                  <AlertDialog.Root>
                    <AlertDialog.Trigger>
                      <Button color='success' variant='flat'>Add new doctor</Button>
                    </AlertDialog.Trigger>
                    <AlertDialog.Content size={"3"}>
                      <AlertDialog.Title>Add new doctor</AlertDialog.Title>
                      <form action="" onSubmit={handleAddDoctor} className='flex flex-col gap-3'>
                        <Input value={doctorForm.first_name} onChange={handleChangeDoctor} name='first_name' variant='underlined' label='First Name' />
                        <Input value={doctorForm.last_name} onChange={handleChangeDoctor} name='last_name' variant='underlined' label='Last Name' />
                        <Input value={doctorForm.father_name} onChange={handleChangeDoctor} name='father_name' variant='underlined' label='Father Name' />
                        <Input value={String(doctorForm.phone_number)} onChange={handleChangeDoctor} name='phone_number' variant='underlined' label='Phone Number' />
                        <Input value={doctorForm.email} onChange={handleChangeDoctor} name='email' variant='underlined' label='Email' />
                        <Input value={doctorForm.profile_picture} onChange={handleChangeDoctor} name='profile_picture' variant='underlined' label='Profile picture' />
                        <Input value={doctorForm.specialization} onChange={handleChangeDoctor} name='specialization' variant='underlined' label='Specialization' />
                        <div className='flex gap-3 justify-end'>
                          <Button size='lg' color='success' type='submit'>Add Doctor</Button>
                          <AlertDialog.Cancel>
                            <Button size='lg' color='danger' type='button'>Close</Button>
                          </AlertDialog.Cancel>
                        </div>
                      </form>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;