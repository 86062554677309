import React, { FC } from 'react'
import { TypePosition } from './Header';
import { motion } from 'framer-motion';

interface Cursorable {
    position: TypePosition;
}

const Cursor: FC<Cursorable> = ({ position }) => {

    const {opacity, width, left} = position;

    return (
        <motion.li
            className='w-[100px] h-[3px] rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 absolute bottom-[-5px]'
            animate={position}
            style={{
                opacity,
                width,
                left
            }}
        >

        </motion.li>
    )
}

export default Cursor