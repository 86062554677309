import Footer from 'rc-footer'
import React from 'react'
import { FaEnvelope, FaGlobe, FaJava, FaPhone, FaReact, FaTelegram } from 'react-icons/fa6'

const WebsiteFooter = () => {
    return (
        <div id='contact-us'>
            <Footer
            className='mt-10'
            maxColumnsPerRow={3}
            columns={[
                {
                    title: "Կապ",
                    items: [
                        {
                            icon: <FaPhone />,
                            title: <p>+374-256-23228</p>,
                            url: "tel:+374-256-23228",
                            description: "Հեռախոսահամար"
                        },
                        {
                            icon: <FaEnvelope />,
                            title: <p>support@stepanavanmc.am</p>,
                            url: "mail:support@stepanavanmc.am",
                            description: "Էլ․ հասցե"
                        }
                    ],
                },
                {
                    title: "Խորհուրդ տրվող հղումներ",
                    items: [
                        {
                            title: <p>Գլխավոր</p>,
                            url: "#top",
                        },
                        {
                            title: <p>Բաժանմունքներ</p>,
                            url: "#departments",
                        },
                        {
                            title: <p>Մեր մասին</p>,
                            url: "#about-us",
                        },
                    ]
                },
            ]}
            bottom="&copy; 2024 Stepanavan Medical Center"
            columnLayout='space-between'
        />
        </div>
    )
}

export default WebsiteFooter