import {
  Accordion,
  AccordionItem,
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  TableCell,
  useDisclosure,
} from "@nextui-org/react";
import { AlertDialog, Table } from "@radix-ui/themes";
import axios from "axios";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";

const Services = () => {
  const [services, setServices] = useState<any[]>([]);
  const [serviceGroups, setServiceGroups] = useState<any>([]);
  const [selected, setSelected] = useState("");
  const [createFormData, setCreateFormData] = useState({
    title: "",
    price: 0,
    count: 0,
  });
  const [createTitle, setCreateTitle] = useState("");
  const [createSelectedIds, setCreatedSelectedIds] = useState<any[]>([null]);
  const { isOpen, onOpenChange, onClose } = useDisclosure();

  const handleCreateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCreateFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateServiceGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = { title: createTitle, service_ids: createSelectedIds };

    console.log(payload);

    await axios.post("https://api.stepanavanmc.am/services/create", payload);
    await fetchServiceGroups();
  };

  const fetchServies = async () => {
    const response = await axios.get(
      "http://api.stepanavanmc.am/service/findAll",
    );
    setServices(response.data);
  };

  const fetchServiceGroups = async () => {
    const response = await axios.get(
      "https://api.stepanavanmc.am/services/findAll",
    );
    const data = response.data;

    const editedData = await Promise.all(
      data.map(async (s: any) => {
        s["services"] = [];

        await Promise.all(
          s.service_ids.map(async (service_id: number) => {
            const response = await axios.get(
              "https://api.stepanavanmc.am/service/findById/" + service_id,
            );
            s["services"].push(response.data);
          }),
        );

        return s;
      }),
    );

    setServiceGroups(editedData);
  };

  const handleCreateSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await axios.post(
      "https://api.stepanavanmc.am/service/create",
      createFormData,
    );
    setCreateFormData({ title: "", price: 0, count: 0 });
    fetchServiceGroups();
  };

  useEffect(() => {
    fetchServiceGroups();
    fetchServies();
  }, []);

  const handePlusCreate = () => {
    setCreatedSelectedIds((prev) => [...prev, null]);
  };

  const handleDeleteGroup = async (id: number) => {
    await axios.delete(`https://api.stepanavanmc.am/services/delete/${id}`);
    await fetchServiceGroups();
  };

  return (
    <div>
      <h2 className="text-2xl mb-4">Service</h2>
      <Alert color="default" title="Please fill out all fields" />
      <Accordion className="mt-5">
        {services &&
          services.map((srv) => (
            <AccordionItem title={srv.title}>
              <table>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>&nbsp;=&gt;&nbsp;{srv.id}</td>
                  </tr>
                  <tr>
                    <td>Հասանելի է (հատ)</td>
                    <td>&nbsp;=&gt;&nbsp;{srv.count}</td>
                  </tr>
                  <tr>
                    <td>Գին (դրամ)</td>
                    <td>&nbsp;=&gt;&nbsp;{srv.count}</td>
                  </tr>
                </tbody>
              </table>
            </AccordionItem>
          ))}
      </Accordion>
      <div className="flex justify-end mt-5">
        <AlertDialog.Root>
          <AlertDialog.Trigger>
            <Button>Add new service</Button>
          </AlertDialog.Trigger>
          <AlertDialog.Content>
            <form onSubmit={handleCreateSubmit}>
              <AlertDialog.Title>Add new service</AlertDialog.Title>
              <AlertDialog.Description>
                <div className="flex flex-col gap-3">
                  <Input
                    onChange={handleCreateChange}
                    name="title"
                    value={createFormData.title}
                    size="lg"
                    label="Enter title"
                  />
                  <Input
                    onChange={handleCreateChange}
                    value={String(createFormData.price)}
                    name="price"
                    type="number"
                    size="lg"
                    label="Enter price"
                  />
                  <Input
                    onChange={handleCreateChange}
                    value={String(createFormData.count)}
                    name="count"
                    type="number"
                    size="lg"
                    label="Enter count"
                  />
                </div>
              </AlertDialog.Description>
              <div className="flex gap-3 mt-4 justify-end">
                <Button type="submit" color="success" variant="flat" size="lg">
                  Add
                </Button>
                <AlertDialog.Cancel>
                  <Button color="danger" variant="flat" size="lg">
                    Close
                  </Button>
                </AlertDialog.Cancel>
              </div>
            </form>
          </AlertDialog.Content>
        </AlertDialog.Root>
      </div>
      <h2 className="text-2xl mb-4">Service Group</h2>
      <Accordion className="mt-5">
        {serviceGroups &&
          serviceGroups.map((srvG: any) => (
            <AccordionItem title={srvG.title}>
              <Table.Root>
                <Table.Header>
                  <Table.Row>
                    <Table.RowHeaderCell>ID</Table.RowHeaderCell>
                    <Table.RowHeaderCell>Name</Table.RowHeaderCell>
                    <Table.RowHeaderCell>Count</Table.RowHeaderCell>
                    <Table.RowHeaderCell>Price</Table.RowHeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {srvG.services &&
                    srvG.services.map((srv: any) => (
                      <Table.Row>
                        <Table.Cell>{srv.id}</Table.Cell>
                        <Table.Cell>{srv.title}</Table.Cell>
                        <Table.Cell>{srv.count}</Table.Cell>
                        <Table.Cell>{srv.price}</Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table.Root>
              <div className="flex justify-end">
                <Button
                  color="danger"
                  variant="flat"
                  onClick={() => handleDeleteGroup(srvG.id)}
                >
                  Remove
                </Button>
              </div>
            </AccordionItem>
          ))}
      </Accordion>
      <div className="justify-end flex">
        <Button onClick={() => onOpenChange()}>Add new service</Button>
        <Modal size="lg" placement="top" isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleCreateServiceGroup}>
            <ModalContent>
              <ModalHeader>Add new service</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-3">
                  <Input
                    onChange={(e) => setCreateTitle(e.target.value)}
                    value={createTitle}
                    size="lg"
                    label="Enter title"
                  />
                  <span className="mt-4 mb-2">Include Services</span>
                </div>
                <div className="flex flex-col gap-3">
                  {createSelectedIds.map((_, i) => (
                    <div className="flex gap-3">
                      <Select
                        onSelectionChange={(keys) => {
                          const newDoctors: any[] = [...createSelectedIds];
                          newDoctors[i] = Number(keys.currentKey);
                          setCreatedSelectedIds(newDoctors);
                        }}
                        label="Select services"
                        size="lg"
                      >
                        {services.map((service) => (
                          <SelectItem key={service.id}>
                            {service.title} ({service.count})
                          </SelectItem>
                        ))}
                      </Select>
                      <Button
                        onClick={handePlusCreate}
                        variant="ghost"
                        color={
                          i === createSelectedIds.length - 1
                            ? "success"
                            : "danger"
                        }
                        className="h-[65px]"
                        size="lg"
                      >
                        {i === createSelectedIds.length - 1 ? (
                          <FaPlus />
                        ) : (
                          <FaMinus />
                        )}
                      </Button>
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter className="flex gap-3 justify-end">
                <Button type="submit" color="success" variant="flat" size="lg">
                  Add
                </Button>
                <Button
                  onClick={() => onClose()}
                  color="danger"
                  variant="flat"
                  size="lg"
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default Services;
