import React, { FC, ReactNode } from 'react';
import Button from './Button';
import { Link } from 'react-router-dom';

interface Cardable {
    icon?: string;
    description?: ReactNode;
    isReport?: boolean;
    reasonId?: string;
    title?: string;
    isView?: boolean;
    id?: number;
}

const Card: FC<Cardable> = ({ icon, description, isReport, reasonId, title, isView, id }) => {
    return (
        <div className='w-full rounded-3xl font-medium bg-[#EFEFEF] p-10 sm:p-5'>
            <div className='flex items-start sm:flex-col gap-5 sm:flex-col-reverse'>
                <div>
                    <div className='flex justify-between items-center sm:items-start'>
                        {
                            title && (
                                <h3 className='mt-5 sm:text-[23px] text-3xl font-bold'>{title}</h3>
                            )
                        }
                    </div>
                    <p className='mt-5'>{description?.toString().slice(0, 160)}...</p>
                </div>
                <img className='w-[100px] sm:w-full rounded-lg' src={icon} alt="" />
            </div>
            <div className='flex justify-end mt-5'>
                {
                    isReport && (
                        <Link to={"/reason/" + reasonId}>
                            <Button rounded='sm' color='secondary' className='font-medium'>
                                Report
                            </Button>
                        </Link>
                    )
                }
                {
                    isView && (
                        <Link to={"/department/" + id}>
                            <Button rounded='sm' color='secondary' className='font-medium'>
                                Իմանալ ավելին
                            </Button>
                        </Link>
                    )
                }
            </div>
        </div>
    )
}

export default Card