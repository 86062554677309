import React from 'react'
import { FaHouseMedical, FaKitMedical } from 'react-icons/fa6'
import { LuBrainCircuit } from 'react-icons/lu'

const Logo = () => {
    return (
        <div className='text-2xl flex items-center gap-3'>
            <div className='animate-pulse delay-200'>
                <FaHouseMedical />
            </div>
            <h3 className='headerLogo animate-pulse tracking-widest select-none font-bold'>Stepanavan MC</h3>
        </div>
    )
}

export default Logo