import React, { FC, useEffect, useRef, useState } from 'react';
import { FaArrowRight, FaMedapps, FaUser, FaUserPlus } from 'react-icons/fa6';
import { HiBars3, HiXMark } from 'react-icons/hi2';
import { LiaDoorOpenSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

interface Dropdownable {
    isShow: boolean;
}

const Dropdown: FC<Dropdownable> = ({ isShow }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.documentElement.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.documentElement.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);

    const handleToggleOpen = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen((prev) => !prev);
    };

    return (
        <div className="relative lg:hidden sm:hidden">
            <button
                className="bg-gray-200 p-2 rounded-full"
                onClick={handleToggleOpen}
            >
                {!isOpen ? <HiBars3 size={30} /> : <HiXMark size={30} />}
            </button>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isOpen && isShow
                        ? { y: 0, opacity: 1 }
                        : { opacity: 0, y: 20, pointerEvents: 'none' }
                }
                className="bg-white p-3 text-center right-0 top-16 shadow-2xl rounded-3xl w-[300px] absolute"
            >
                <ul className="flex flex-col">
                    <li className="p-3 hover:bg-gray-200 rounded-2xl">
                        <Link to="/profile" className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <FaUser />
                                <span>Profile</span>
                            </div>
                            <span>
                                <FaArrowRight />
                            </span>
                        </Link>
                    </li>
                    <li className="p-3 hover:bg-gray-200 rounded-2xl">
                        <Link
                            to="/profile"
                            className="flex items-center justify-between"
                        >
                            <div className="flex items-center gap-4">
                                <FaUserPlus />
                                <span>Referral program</span>
                            </div>
                            <span>
                                <FaArrowRight />
                            </span>
                        </Link>
                    </li>
                    <li className="p-3 hover:bg-gray-200 rounded-2xl">
                        <Link to="/go" className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <FaMedapps />
                                <span>Courses</span>
                            </div>
                            <span>
                                <FaArrowRight />
                            </span>
                        </Link>
                    </li>
                    <li className="p-3 hover:bg-red-100 text-red-500 rounded-2xl">
                        <Link to="#" className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <LiaDoorOpenSolid />
                                <span>Log out</span>
                            </div>
                            <span>
                                <FaArrowRight />
                            </span>
                        </Link>
                    </li>
                </ul>
            </motion.div>
        </div>
    );
};

export default Dropdown;
