import { Button, Input } from '@nextui-org/react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Flex } from '@radix-ui/themes'
import React, { ChangeEvent, useState } from 'react'
import { FaHouseMedical } from 'react-icons/fa6'
import Cookies from "cookies-js";
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const [formData, setData] = useState({
    email: "",
    password: ""
  });
  const [showMessage, setShowMessage] = useState(false);

  const admin = {
    email: "administration@stepanavanmc.am",
    password: "Stepanavan-BK2024$%^&"
  };

  const navigate = useNavigate();

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData.email === admin.email && formData.password === admin.password) {
      navigate("/adm.pnl?route=/home/site-managemant");
      Cookies.set("hasPermit", "true", { expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) });
    } else {
      setShowMessage(true);
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }))
    setShowMessage(false);
  }

  return (
    <div className='max-w-[400px] mx-auto flex flex-col items-center w-full mt-10'>
      <FaHouseMedical className='animate-pulse' size={30} />
      <h1 className='text-2xl font-bold'>Admin panel of StepanavanMC</h1>
      <form onSubmit={handleSubmit} className='w-full mt-5 flex flex-col gap-5'>
        <Input onChange={handleChange} name='email' label='Էլ․ հասցե' />
        <Input type='password' onChange={handleChange} name='password' label='Գաղտնաբառ' />
        {showMessage && <p className='text-sm text-center text-red-500'>Խնդրում ենք մուտքագրել ճիշտ տվյալներ</p>}
        <Button type='submit' size='lg' color='primary'>Մուտք</Button>
      </form>
    </div>
  )
}

export default Login