// @ts-ignore
import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Container from "../components/Container";
import Card from "../components/Card";
import { useLocation } from "react-router-dom";
import "rc-footer/assets/index.css";
import WebsiteFooter from "../components/WebsiteFooter";
import CookieBanner from "../components/CookieBanner";
import * as api from "../services/api";
import axios from "axios";
import { FaCircleInfo, FaEnvelope, FaPhone, FaUser } from "react-icons/fa6";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { Table } from "@radix-ui/themes";

const Landing = () => {
  const location = useLocation();
  const differenceSectionRef = useRef(null);
  const [doctors, setDoctors] = useState<any>([]);
  const [websiteContent, setWebsiteContent] = useState<{
    id: 0;
    banner_title: "";
    banner_descr: "";
    about_us_descr: "";
    phone_number: "";
    email: "";
  }>();
  const [departments, setDepartments] = useState<any>();
  const [services, setServices] = useState<any>([]);

  const fetchDoctors = async () => {
    const response = await axios.get(
      "https://api.stepanavanmc.am/doctors/findAll",
    );
    setDoctors(response.data);
  };

  const fetchServices = async () => {
    const response = await axios.get(
      "https://api.stepanavanmc.am/services/findAll",
    );
    const data = response.data;

    const editedData = await Promise.all(
      data.map(async (s: any) => {
        s["services"] = [];

        await Promise.all(
          s.service_ids.map(async (service_id: number) => {
            const response = await axios.get(
              "https://api.stepanavanmc.am/service/findById/" + service_id,
            );
            s["services"].push(response.data);
          }),
        );

        return s;
      }),
    );

    setServices(editedData);
  };

  const fetchDepartments = async () => {
    const response = await axios.get(
      "https://api.stepanavanmc.am/departments/findAll",
    );
    setDepartments(response.data);
  };

  const fetchWebsiteContent = async () => {
    try {
      const data = await api.fetchWebsiteContent();
      setWebsiteContent(data[0]);
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  useEffect(() => {
    const sectionId = location.hash.slice(1);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    fetchWebsiteContent();
    fetchDepartments();
    fetchDoctors();
    fetchServices();
  }, [location.hash]);

  return websiteContent ? (
    <>
      <Header />
      <Banner websiteContent={websiteContent} />
      <section id="about-us" className="mt-[69px]">
        <Container>
          <h2 className="text-[40px] font-bold">Մեր մասին</h2>
          <div className="flex flex-col gap-5 mt-5 text-lg">
            <p
              dangerouslySetInnerHTML={{
                __html: websiteContent.about_us_descr.replaceAll(
                  "\n",
                  "<br />",
                ),
              }}
            ></p>
          </div>
        </Container>
      </section>
      <section id="services" className="mt-[69px]">
        <Container>
          <h2 className="text-[40px] font-bold">Ծառայություններ</h2>
          <Accordion>
            {services &&
              services.map((service: any) => (
                <AccordionItem key={service.id} title={service.title}>
                  <Table.Root>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>ID</Table.Cell>
                        <Table.Cell>Name</Table.Cell>
                        <Table.Cell>Count</Table.Cell>
                        <Table.Cell>Price</Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {service.services
                        ? service.services.map((srv: any) => (
                            <Table.Row key={srv.id}>
                              <Table.Cell>{srv.id}</Table.Cell>
                              <Table.Cell>{srv.title}</Table.Cell>
                              <Table.Cell>{srv.count}</Table.Cell>
                              <Table.Cell>{srv.price}</Table.Cell>
                            </Table.Row>
                          ))
                        : []}
                    </Table.Body>
                  </Table.Root>
                </AccordionItem>
              ))}
          </Accordion>
        </Container>
      </section>
      <section id="departments" className="mt-[69px]">
        <Container>
          <h2 className="text-[40px] font-bold">Բաժանմունքներ</h2>
          <ul className="grid grid-cols-2 gap-5 mt-10 sm:grid-cols-1">
            {departments &&
              departments.map((department: any, i: number) => (
                <Card
                  isView
                  key={i}
                  title={department.title}
                  id={department.id}
                  description={department.description}
                  isReport={false}
                />
              ))}
          </ul>
        </Container>
      </section>
      <section id="we" className="mt-[69px]">
        <Container>
          <h2 className="text-[40px] font-bold">Անձնակազմ</h2>
          <div className="grid grid-cols-4 mt-5 gap-4 sm:grid-cols-1">
            {doctors &&
              doctors.map((doctor: any, i: number) => (
                <div className="aspect-square">
                  <img
                    className="rounded-2xl w-full h-full object-cover"
                    src={
                      "https://stepanavanmc.am/static/media/doctors/" +
                      doctor.profile_picture
                    }
                    alt=""
                  />
                  <h2 className="text-2xl font-bold mt-5">
                    {doctor.first_name + " " + doctor.last_name}
                  </h2>
                  <div className="flex gap-2 items-center mt-4 text-cyan-700">
                    <FaPhone />
                    <a href={"tel:" + doctor.phone_number}>
                      {doctor.phone_number}
                    </a>
                  </div>
                  <div className="flex gap-2 items-center mt-2 text-cyan-700">
                    <FaEnvelope />
                    <a href={"mail:" + doctor.email}>{doctor.email}</a>
                  </div>
                  <div className="flex gap-2 items-center mt-2 text-cyan-700">
                    <FaUser />
                    {doctor.first_name +
                      " " +
                      doctor.last_name +
                      " " +
                      doctor.father_name}
                  </div>
                  <div>
                    {
                        doctor.specialization && (
                            <div className="flex gap-2 items-center mt-2 text-cyan-700">
                                <FaCircleInfo />
                                {doctor.specialization}
                            </div>
                        )
                    }
                  </div>
                </div>
              ))}
          </div>
        </Container>
      </section>
      <WebsiteFooter />
      <CookieBanner />
    </>
  ) : (
    <p>Loading...</p>
  );
};

export default Landing;
