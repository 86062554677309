import React, { FC, useEffect, useState } from "react";
import Container from "./Container";
import Tab from "./Tab";
import Cursor from "./Cursor";
import Button from "./Button";
import { HiBars3, HiMiniXMark } from "react-icons/hi2";
import { Link, useLocation } from "react-router-dom";
import Logo from "./Logo";
import { motion } from "framer-motion";
import Dropdown from "./Dropdown";

export type TypePosition = {
  width: number;
  opacity: number;
  left: number;
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<TypePosition>({
    left: 0,
    width: 0,
    opacity: 0,
  });

  const screenHeight = window.innerHeight + "px";

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const location = useLocation();

  function smoothChangeColor(newColor: string): void {
    const meta = document.getElementById("theme-color");
    if (!meta) return;
    const currentColor = meta.getAttribute("content");

    if (currentColor !== newColor) {
      meta.style.transition = `content ${200}ms`;
      meta.setAttribute("content", newColor);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 0) {
        setIsVisible(false);
        smoothChangeColor("#000000");
      } else {
        setIsVisible(true);
        smoothChangeColor("#FFFFFF");
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const routes = [
    {
      textContent: "Գլխավոր",
      to: "#top",
      type: "page",
    },
    {
      textContent: "Մեր մասին",
      to: "#about-us",
      type: "page",
    },
    {
      textContent: "Ծառայություններ",
      to: "#services",
      type: "page",
    },
    {
      textContent: "Բաժանմունքներ",
      to: "#departments",
      type: "page",
    },
    {
      textContent: "Հետադարձ կապ",
      to: "#contact-us",
      type: "page",
    },
    {
      textContent: "Անձնակազմ",
      to: "#we",
      type: "page",
    },
  ];

  const handleOpen = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <div
        className={`bg-white z-50 sticky top-0 transition duration-500 ${isVisible ? "translate-y-0" : "-translate-y-[100px]"}`}
      >
        <Container className="headerContainer flex items-center justify-between py-5">
          <Logo />
          <ul className="flex gap-5 sm:hidden relative items-center">
            {routes.map((route, i) => (
              <Tab key={i} to={"/" + route.to} setPosition={setPosition}>
                {route.textContent}
              </Tab>
            ))}

            <Cursor position={position} />
          </ul>
          <button
            onClick={handleOpen}
            className="bg-gray-200 p-2 lg:hidden rounded-full"
          >
            <HiBars3 size={30} />
          </button>

          <Dropdown isShow={isVisible} />
        </Container>
      </div>
      {isOpen && (
        <motion.div
          style={{
            height: screenHeight,
          }}
          className="fixed top-0 z-50 lg:hidden bg-white overflow-hidden left-0 w-full"
        >
          <header className="p-5 flex justify-between items-center">
            <Logo />
            <button
              onClick={handleClose}
              className="bg-gray-200 p-2 rounded-full"
            >
              <HiMiniXMark size={30} />
            </button>
          </header>
          <ul className="px-5 flex flex-col gap-5">
            {routes.map((route, i) => (
              <li>
                <Link
                  onClick={handleClose}
                  className={`text-2xl font-bold ${route.to === location.hash && "text-cyan-500"}`}
                  to={route.to}
                >
                  {route.textContent}
                </Link>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </>
  );
};

export default Header;
