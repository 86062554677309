import React, { FC, useEffect, useRef, useState } from "react";
import { image, Button as NextButton } from "@nextui-org/react";
import Container from "./Container";
import Button from "./Button";
import { useScroll } from "framer-motion";
import { FaCircleInfo } from "react-icons/fa6";
import hero from "../assets/images/banner.jpg";
import image1 from "../assets/images/image1.jpg";
import image2 from "../assets/images/banner2.jpeg";
import { AlertDialog, Table } from "@radix-ui/themes";
import { services } from "../data/services";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";

const DROPBOX_ACCESS_TOKEN =
  "sl.u.AFmY0jacMqGh3-Znlh1JrvFcFWZewASFtaP41nojfclbZ01Plg8DM1WdK0aAJvcYDNd4d-THAVfqsMAUEouGpgw8-ONZDz_m82i3Yu0JrYjrZP_9AQ6ufguNnGfS7GKYrA2oGdsm4nKb9LQkp1YddM0dMBxHnLRsRFAlQJlo7VAVg67eTRVa0D1riw27TeB2Mhp1aOcF94wOi7-r7M11gVaUYUNRIcpzLB1NS-PSy0OOxYVfQ2_rJazoAJ3VaixAYxzAnIqzcUV3KtOq7i_rrDlpxN0aTUFPpfLED8CHiD-gE19HtwXM2ASn0cyonV_G8zGBLWsl4MyY1P8KuVo5t0i8ZT4BOSpX_J8QRAuA9VXsPA7JxGmM-m7oPv01LK_fLjix_kjHAA162kSJ71b03bwIkyPQ1SM5P6owE3_CfYjnwJekW-Ljxq0b4ZRW-4hpkJTyJrQi_5heCGB_wng4t-yXnKrj9JTZ-Xt0eineNNu0bxsX88j2l5P20dj8_5w4QLi8lfCPu-PPtgE5daEGtAAv6ktiZ6dL8kZNyKP7KX2JdnD7iME623I60un8JT33MTN_js_J3lHZFYb6kl8mMkRGguQKefSg-oE10FhiCm74LH-kyVdufG7N16FHBZa3OuwtOmI0GIo5SqX6IErYU8QBP95bUPyiyCk8UpF6HPRKcQTiGLUukErAEetdLZ_2RBp8gRtZ27y5KKjUqCS0AcWIWoePvA3zGAiubg3SbDKXtQAQqzX_B1lTm1nV664X9TIweEq0qfvRKmaQfClqp0UOKxG_p1XsguJc6ZmWtU7p22nNnRqk_VjVFaUaKWpU6MOQfmYyY6mH8VhhgcTiYjfW-V47YeGiUJB60FSrxqKXYWcIZPyDyZjw2G9M11sQU0tbTafr57dDlg0TJ_J2kpap5VPNp1svNE_YrOHQnCO-dc67QDpiapfNc_GyYNIV96VQwPtvKEwTP5Ef59EWt3GLHyWZtWyieJHIAsS4mj8K5ix_JOXrYtxO_jFBo1-1NBCEIDLSS8CWwIfjo0EBZIPu_dDZ_MTY5HZR65W6Ps3QtNXWO80UwRY8xyUIlnoRvc4mUkxH5ZL1eUG86mN9OSJrKou8o72LAy5u5sP32w9K350HwLVxvLmvFrqqHbA4Ab88M9lbEtu7sMcm3s0PCnRUgG-OUBEL6OBPfvGgNrR40Xa_M8OnYyoH8w2RLQa05ySSmZlNRaUpqI22YctFCTGOG9g6heRRhh1wDT3bHITTMU5IXe0synWubSoVyv-0E-G-FOdQw960rBKNNnn24vwF";
const DROPBOX_API_URL = "https://api.dropboxapi.com/2/files/list_folder";
const DROPBOX_TEMP_LINK_API =
  "https://api.dropboxapi.com/2/files/get_temporary_link";

const Banner: FC<any> = ({ websiteContent }) => {
  const bannerHeight = window.innerHeight - 84 + "px";
  const { scrollYProgress } = useScroll();
  const [scrollY, setScrollY] = useState(0);
  const [images, setImages] = useState<string[]>();
  const ref = useRef<HTMLDivElement>(null);
  const [departments, setDepartments] = useState<any>([]);
  const [doctors, setDoctors] = useState<any>([]);

  const bannerImages = ["image1", "image2", "image3"];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      const response = await axios.get(
        "https://api.stepanavanmc.am/doctors/findAll",
      );
      setDoctors(response.data);
    };

    fetchDoctors();
  }, []);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((latest) => {
      setScrollY(latest);
    });
    return () => unsubscribe();
  }, [scrollYProgress]);

  const scale = scrollY * 200;

  const formatter = new Intl.NumberFormat("fr-FR");

  const pageHeight = document.documentElement.scrollHeight;
  const bh = document.getElementById("top")?.offsetHeight ?? 0;

  const percentScrolled = (scrollY * 100) / bh;

  console.log("percentScrolled: " + percentScrolled);

  const fetchDepartments = async () => {
    const response = await axios.get(
      "https://api.stepanavanmc.am/departments/findAll",
    );
    setDepartments(response.data);
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  return (
    <section
      className="relative overflow-hidden h-[800px]"
      id="top"
      style={{
        opacity: 1 - scrollY / bh,
      }}
    >
      <Slider swipe {...settings}>
        {bannerImages.map((src) => (
          <div className="h-full">
            <img
              className="w-full h-[100%] object-cover object-bottom"
              src={
                "https://stepanavanmc.am/static/media/banner/" + src + ".jpg"
              }
              alt="Banner Image 2"
            />
          </div>
        ))}
      </Slider>

      <div
        ref={ref}
        className="absolute h-full top-0 left-0 overflow-hidden w-full bg-[#00000099]"
      >
        <div
          className="w-full h-full"
          style={{
            transform: `translateY(${scale * 3}px)`,
          }}
        >
          <Container className="py-[150px]">
            <div className="text-white flex flex-col gap-5">
              <h1 className="lg:text-[64px] text-white text-[52px] font-extrabold lg:leading-[80px]">
                {websiteContent && websiteContent.banner_title}
              </h1>
              <h3 className="lg:text-[25px] text-[25px] max-w-[625px]">
                {websiteContent && websiteContent.banner_descr}
              </h3>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default Banner;
