import React, { FC, ReactNode, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { TypePosition } from './Header';

interface Tabable {
    to?: string;
    children?: ReactNode | string;
    setPosition?: (position: TypePosition) => any;
}

const Tab: FC<Tabable> = ({ to, children, setPosition }) => {
    const ref = useRef<HTMLLIElement>(null);
    const location = useLocation();

    const handleChangePosition = () => {
        if (!ref.current) return;

        const { width } = ref.current.getBoundingClientRect();

        if (setPosition) {
            setPosition({
                width,
                opacity: 1,
                left: ref.current.offsetLeft,
            });
        }
    }

    return (
        <li
            ref={ref}
            onClick={handleChangePosition}
            className={`${location.hash === to ? "bg-gradient-to-r from-cyan-500 to-blue-500 bg-clip-text text-transparent" : ""} transition duration-500`}
        >
            <Link to={to ? to : ""}>{children}</Link>
        </li>
    )
}

export default Tab