import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import { departments } from "../data/departments";
import { FaArrowLeft, FaEnvelope, FaPhone, FaUser } from "react-icons/fa6";
import WebsiteFooter from "../components/WebsiteFooter";
import { useEffect, useState } from "react";
import axios from "axios";

const Department = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const idNumber = Number(id);
    const [department, setDepartment] = useState<any>();
    const [doctors, setDoctors] = useState<any>();

    useEffect(() => {
        fetchDepartment();
    }, []);

    useEffect(() => {
        if (department) {
            fetchDoctors();
        }
    }, [department]);

    if (!id || isNaN(idNumber)) return <></>;

    const handleGoBack = () => {
        navigate(-1);
    };

    const fetchDepartment = async () => {
        const response = await axios.get("https://api.stepanavanmc.am/departments/findById/" + id);
        console.log(response.data);
        setDepartment(response.data);
    }

    const fetchDoctors = async () => {
        const response = await Promise.all(department.doctor_ids.map(async (doctor_id: number, i: number) => {
            const response = await axios.get("https://api.stepanavanmc.am/doctors/findById/" + doctor_id);
            return response.data;
        }));
        
        setDoctors(response);
    }

    return (
            <div>
                <Header />
                <div className="max-w-[1269px] mx-auto mt-5 p-[20px]">
                    {department && (
                        <>
                            <button onClick={handleGoBack} className="flex gap-2 text-bold items-center text-2xl">
                                <FaArrowLeft />
                            </button>
                            <h1 className="font-bold text-4xl mt-5">{department.title}</h1>
                            <div className="grid grid-cols-4 mt-5 gap-4 sm:grid-cols-1">
                                {
                                    doctors && doctors.map((doctor: any, i: number) => (
                                        doctor && (
                                            <div>
                                            <img className="rounded-2xl" src={"https://stepanavanmc.am/static/media/doctors/" + doctor.profile_picture} alt="" />
                                            <h2 className="text-2xl font-bold mt-5">{doctor.first_name + " " + doctor.last_name}</h2>
                                            <div className="flex gap-2 items-center mt-4 text-cyan-700">
                                                <FaPhone />
                                                <a href={"tel:" + doctor.phone_number}>{doctor.phone_number}</a>
                                            </div>
                                            <div className="flex gap-2 items-center mt-2 text-cyan-700">
                                                <FaEnvelope />
                                                <a href={"mail:" + doctor.email}>{doctor.email}</a>
                                            </div>
                                            <div className="flex gap-2 items-center mt-2 text-cyan-700">
                                                <FaUser />
                                                {doctor.first_name + " " + doctor.last_name + " " + doctor.father_name}
                                            </div>
                                            {
                                                doctor.specialization && (
                                                    <div className="flex gap-2 items-center mt-2 text-cyan-700">
                                                        <FaUser />
                                                        {doctor.specialization}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        )
                                    ))
                                }
                            </div>
                            <p className="mt-6">{department.description}</p>
                        </>
                    )}
                </div>
                <WebsiteFooter />
            </div>
    ) 
}

export default Department;