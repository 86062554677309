import axios from 'axios';

const API_BASE_URL = 'https://api.stepanavanmc.am';

export const fetchDepartments = async () => {
  const response = await axios.get(`${API_BASE_URL}/departments/findAll`);
  return response.data;
};

export const createDepartment = async (departmentData: {
  title: string;
  description: string;
  doctor_id: number;
  price: number;
}) => {
  const response = await axios.post(`${API_BASE_URL}/departments/create`, departmentData);
  return response.data;
};

export const updateDepartment = async (id: number, departmentData: {
  title: string;
  description: string;
  doctor_id: number;
  price: number;
}) => {
  const response = await axios.put(`${API_BASE_URL}/departments/update/${id}`, departmentData);
  return response.data;
};

export const deleteDepartment = async (id: number) => {
  const response = await axios.delete(`${API_BASE_URL}/departments/delete/${id}`);
  return response.data;
};

export const fetchWebsiteContent = async () => {
  const response = await axios.get(`${API_BASE_URL}/website-content/findAll`);
  return response.data;
};

export const updateWebsiteContent = async (id: number, columnName: string, value: string) => {
  const response = await axios.post(`${API_BASE_URL}/website-content/update-column/${id}`, {
    column_name: columnName,
    value,
  });
  return response.data;
};