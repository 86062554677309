import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import "@radix-ui/themes/styles.css";
import { Theme } from '@radix-ui/themes';
import Register from './pages/Register';
import Login from './pages/Login';
import AdminPanel from './pages/AdminPanel';
import Department from './pages/Department';

const App = () => {
  return (
    <>
      <Theme>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/adm.pnl' element={<AdminPanel />} />
            <Route path='/department/:id' element={<Department />} />
          </Routes>
        </BrowserRouter>
      </Theme>
    </>
  )
}

export default App